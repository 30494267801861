import * as React from "react";

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ label, ...props }, ref) => {
    return (
      <div className="flex flex-col mb-4">
        <label className="text-sm font-semibold mb-2">{label}</label>
        <input
          ref={ref}
          {...props}
          className="border rounded px-4 py-2 focus:outline-none focus:ring focus:border-blue-300"
        />
      </div>
    );
  }
);

Input.displayName = "Input";

export default Input;
