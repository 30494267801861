import React, { useEffect, useState } from "react";
import { SunIcon, MoonIcon } from "@radix-ui/react-icons";

export const ThemeToggle = () => {
  const [isDark, setIsDark] = useState(false);

  useEffect(() => {
    const prefersDark = window.matchMedia(
      "(prefers-color-scheme: light)"
    ).matches;
    setIsDark(prefersDark);

    if (prefersDark) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, []);

  const toggleTheme = () => {
    if (isDark) {
      document.documentElement.classList.remove("dark");
    } else {
      document.documentElement.classList.add("dark");
    }
    setIsDark(!isDark);
  };

  return (
    <div
      onClick={toggleTheme}
      className={`relative inline-flex h-8 w-16 cursor-pointer items-center rounded-full bg-secondary transition-colors ${
        isDark ? "justify-end" : "justify-start"
      }`}
    >
      <div className="w-6 h-6 rounded-full bg-black flex items-center justify-center transition-transform">
        {isDark ? (
          <MoonIcon className="text-white" />
        ) : (
          <SunIcon className="text-white" />
        )}
      </div>
    </div>
  );
};
