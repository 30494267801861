import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/globals.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import { BrowserRouter as Router } from "react-router-dom";
import Auth0ProviderWithHistory from "./auth/Auth0ProviderWithHistory";

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LAUNCHDARKLY_CLIENT_SIDE_ID!,
    context: {
      kind: "user",
      key: "default-key",
      name: "Amaleki",
    },
  });

  const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
  );

  root.render(
    <React.StrictMode>
      <Router>
        <Auth0ProviderWithHistory>
          <LDProvider>
            <App />
          </LDProvider>
        </Auth0ProviderWithHistory>
      </Router>
    </React.StrictMode>
  );
})();

// For measuring app performance
reportWebVitals();
