import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "../components/ui/Button";

const Login: React.FC = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
      <div className="w-full max-w-md bg-white p-8 rounded-lg shadow-lg">
        <h1 className="text-2xl font-bold mb-6 text-center">
          Login to Your Account
        </h1>
        <Button
          onClick={() => loginWithRedirect()}
          className="w-full mt-4 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
        >
          Log In with Auth0
        </Button>
      </div>
    </div>
  );
};

export default Login;
