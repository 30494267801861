import React, { useEffect, useState, useRef } from "react";
import { Switch } from "./ui/Switch";
import { Label } from "./ui/Label";

const JudoBar: React.FC = () => {
  const [flags, setFlags] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState(""); // For search functionality
  const [isExpanded, setIsExpanded] = useState(false); // To control menu expansion
  const [showSearch, setShowSearch] = useState(false); // To show/hide the search bar
  const apiKey = process.env.REACT_APP_LAUNCHDARKLY_API_KEY!;
  const projectKey = process.env.REACT_APP_LAUNCHDARKLY_PROJECT_KEY!;
  const environmentKey = process.env.REACT_APP_LAUNCHDARKLY_ENV_KEY!;
  const ref = useRef<HTMLDivElement>(null); // Ref for detecting outside clicks

  // Fetch details for a specific feature flag
  const fetchFlagDetail = async (flagKey: string) => {
    const url = `https://app.launchdarkly.com/api/v2/flags/${projectKey}/${flagKey}`;
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: apiKey,
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      return data || null;
    } catch (error) {
      console.error(`Error fetching details for flag: ${flagKey}`, error);
      return null;
    }
  };

  // Fetch all flags, including their current state for each specific flag
  const fetchFlags = async () => {
    const url = `https://app.launchdarkly.com/api/v2/flags/${projectKey}`;
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: apiKey,
          "Content-Type": "application/json",
        },
      });
      const flagDetails = await response.json();
      const flagList = flagDetails.items || [];

      // Fetch detailed information for each flag
      const detailedFlags = await Promise.all(
        flagList.map(async (flag: any) => {
          const detailedFlag = await fetchFlagDetail(flag.key);
          return detailedFlag;
        })
      );

      setFlags(detailedFlags.filter((flag: any) => flag !== null)); // Set flags that were successfully retrieved
    } catch (error) {
      console.error("Error fetching flags:", error);
    }
  };

  // Toggle feature flag state
  const toggleFlag = async (flagKey: string, currentState: boolean) => {
    const url = `https://app.launchdarkly.com/api/v2/flags/${projectKey}/${flagKey}`;

    try {
      await fetch(url, {
        method: "PATCH",
        headers: {
          Authorization: apiKey,
          "Content-Type": "application/json",
        },
        body: JSON.stringify([
          {
            op: "replace",
            path: `/environments/${environmentKey}/on`,
            value: !currentState,
          },
        ]),
      });

      // Refetch flags to update state after the change
      fetchFlags();
    } catch (error) {
      console.error("Error toggling flag:", error);
    }
  };

  useEffect(() => {
    fetchFlags();
  }, []);

  // Close the dropdown and hide search bar when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setIsExpanded(false); // Close menu when clicking outside
        setShowSearch(false); // Hide the search bar and reset to showing only the circle
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  // Count active feature flags
  const activeFlagCount = flags.filter(
    (flag) => flag.environments?.[environmentKey]?.on
  ).length;

  return (
    <div ref={ref} className="fixed top-4 right-4">
      {/* Show the Active Flag Counter initially - clicking this reveals the search bar */}
      {!showSearch && (
        <div
          className="flex items-center justify-center bg-gray-500 text-white text-xs w-6 h-6 rounded-full cursor-pointer"
          onClick={() => setShowSearch(true)}
        >
          {activeFlagCount}
        </div>
      )}

      {/* Search Box and Flag Counter on the same row */}
      {showSearch && (
        <div className="relative mt-4 flex items-center">
          <input
            type="text"
            value={searchTerm}
            onFocus={() => setIsExpanded(true)} // Expand when search is focused
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Feature Flags"
            className="w-full p-2 pl-4 pr-10 text-sm text-black border rounded-md border-gray-300 focus:outline-none"
          />
          {/* Active Flag Counter - only show this in expanded mode */}
          <div className="ml-2 flex items-center justify-center bg-gray-500 text-white text-xs w-6 h-6 rounded-full">
            {activeFlagCount}
          </div>
        </div>
      )}

      {/* Feature Flag List (Visible only when expanded or search term is entered) */}
      <div
        className={`transition-all duration-300 ease-in-out bg-white bg-opacity-75 shadow-lg p-4 rounded-lg mt-2 ${
          isExpanded ? "block" : "hidden"
        }`}
      >
        {flags.length > 0 ? (
          flags
            .filter((flag) =>
              flag.key.toLowerCase().includes(searchTerm.toLowerCase())
            ) // Filter by search term
            .map((flag) => {
              const isEnabled =
                flag.environments?.[environmentKey]?.on || false;

              return (
                <div
                  key={flag.key}
                  className="flex items-center space-x-3 mb-2"
                >
                  <Switch
                    id="airplane-mode"
                    checked={isEnabled}
                    onCheckedChange={() => toggleFlag(flag.key, isEnabled)}
                  />
                  <Label style={{ color: "black" }}>{flag.key}</Label>
                </div>
              );
            })
        ) : (
          <p>No feature flags available.</p>
        )}
      </div>
    </div>
  );
};

export default JudoBar;
