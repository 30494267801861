// src/pages/Home.tsx
import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "../components/ui/Button";
import FeatureFlagToggler from "../components/JudoBar";
import AppSidebar from "../components/AppSidebar";

const Home: React.FC = () => {
  const { isAuthenticated, logout, user } = useAuth0();

  return isAuthenticated ? (
    <div className="Home p-6">
      <AppSidebar />
      <h1>Welcome, {user?.name}</h1>
      <Button
        onClick={() =>
          logout({
            logoutParams: {
              returnTo: window.location.origin,
            },
          })
        }
      >
        Logout
      </Button>
      <div
        style={{
          position: "fixed",
          top: "10px",
          right: "10px",
          zIndex: 9999,
        }}
      >
        <FeatureFlagToggler />
      </div>
    </div>
  ) : (
    <div>You are not authenticated</div>
  );
};

export default Home;
